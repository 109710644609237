import React, { useEffect, useContext, lazy } from 'react';
import { useHistory, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import MainSidebar from '../../components/sidebar/MainSidebar';
import Footer from '../../components/footer/Footer';
import { UserContext } from '../../helpers/contexts';
import { userActions } from '../../helpers/actions';
import { getAutoLogout, getToken, getUser, removeUser } from '../../helpers/common';
import ErrorHandler from '../ErrorPage';

const LoginPage = lazy(() => import('../login/LoginPage'));
const HomePage = lazy(() => import('../home/HomePage'));
const InstructorsPage = lazy(() => import('../instructors/InstructorsPage'));
const ClassPage = lazy(() => import('../classes/ClassPage'));
const StudentPage = lazy(() => import('../students/StudentPage'));
const StudentDetailsPage = lazy(() => import('../students/StudentDetails'));
const CoursesPage = lazy(() => import('../courses/CoursesPage'));
const SectionsPage = lazy(() => import('../sections/SectionsPage'));
const QuizzesPage = lazy(() => import('../quizzes/QuizzesPage'));
const MarksPage = lazy(() => import('../marks/MarksPage'));
const StudentMarksPage = lazy(() => import('../marks/StudentMarks'));
const StoriesPage = lazy(() => import('../stories/StoriesPage'));
const ChartsPage = lazy(() => import('../charts/ChartsPage'));
const PrivacyPage = lazy(() => import('../privacy/PrivacyPage'));
const DeleteAccountPage = lazy(() => import('../delete-account/DeleteAccount'));

export default function Routing() {
	const { push } = useHistory();
	const { dispatch, token } = useContext(UserContext);
	const savedToken = getToken();
	const { pathname } = useLocation();

	useEffect(() => {
		(function () {
			if (savedToken) {
				if (getAutoLogout() === new Date().toLocaleDateString()) {
					removeUser();
					push('/login');
					return;
				}

				const user = getUser();
				const data = {
					token: savedToken,
					user,
				};
				dispatch(userActions.setUser(data));
				return;
			}

			if (pathname !== '/privacy') push('/login');
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{token ? (
				<>
					<Switch>
						<Route
							exact
							path="/"
							render={(props) => (
								<ErrorHandler {...props}>
									<HomePage {...props} />
								</ErrorHandler>
							)}
						/>

						<Route
							exact
							path="/instructors"
							render={(props) => (
								<ErrorHandler {...props}>
									<InstructorsPage {...props} />
								</ErrorHandler>
							)}
						/>

						<Route
							exact
							path="/classes"
							render={(props) => (
								<ErrorHandler {...props}>
									<ClassPage {...props} />
								</ErrorHandler>
							)}
						/>

						<Route
							exact
							path="/students"
							render={(props) => (
								<ErrorHandler {...props}>
									<StudentPage {...props} />
								</ErrorHandler>
							)}
						/>

						<Route
							exact
							path="/courses"
							render={(props) => (
								<ErrorHandler {...props}>
									<CoursesPage {...props} />
								</ErrorHandler>
							)}
						/>

						<Route
							exact
							path="/sections"
							render={(props) => (
								<ErrorHandler {...props}>
									<SectionsPage {...props} />
								</ErrorHandler>
							)}
						/>

						<Route
							exact
							path="/student"
							render={(props) => (
								<ErrorHandler {...props}>
									<StudentDetailsPage {...props} />
								</ErrorHandler>
							)}
						/>

						<Route
							exact
							path="/quizzes"
							render={(props) => (
								<ErrorHandler {...props}>
									<QuizzesPage {...props} />
								</ErrorHandler>
							)}
						/>

						<Route
							exact
							path="/marks"
							render={(props) => (
								<ErrorHandler {...props}>
									<MarksPage {...props} />
								</ErrorHandler>
							)}
						/>

						<Route
							exact
							path="/student-marks"
							render={(props) => (
								<ErrorHandler {...props}>
									<StudentMarksPage {...props} />
								</ErrorHandler>
							)}
						/>

						<Route
							exact
							path="/stories"
							render={(props) => (
								<ErrorHandler {...props}>
									<StoriesPage {...props} />
								</ErrorHandler>
							)}
						/>

						<Route
							exact
							path="/charts"
							render={(props) => (
								<ErrorHandler {...props}>
									<ChartsPage {...props} />
								</ErrorHandler>
							)}
						/>
						<Redirect to="/" />
					</Switch>
					<MainSidebar />
					<Footer />
				</>
			) : (
				<Switch>
					<Route path="/login" component={LoginPage} />
				</Switch>
			)}

			<Route path="/privacy" component={PrivacyPage} />
			<Route path="/app/deleteAccount" component={DeleteAccountPage} />
		</>
	);
}
